
/* Global Colors */
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --accent-color: #333366;
  --accent-color-rgb: 51, 51, 102;
  --accent-color-hover: #8dcfe7;
  --accent-color-hover-rgb: 141, 207, 231;
  --heading-color: #32353a;
  --heading-color-rgb: 50, 53, 58;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}

/*-----------------------------------
# AUTH FORM
------------------------------------*/
.auth-container {
  background-image: linear-gradient(to bottom right, var(--background-color), rgba(var(--heading-color-rgb), 0.4));
} 

.form-input {
  
  input {
    width: 100%;
    border: 1px solid #000;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: rgb(var(--default-color-rgb));
  }

  input:focus {
    outline: none;
    /* color: rgb(var(--default-color-rgb)); */
    color: #D09F0F;
    /* border: 0 solid #000; */
    border-bottom: 1 solid #000;
  }

  &:focus-within {
    label {
      /* color: #E7B110; */
      color: #D09F0F;
    }
  }
}

/*-----------------------------------
# AUTH FORM
------------------------------------*/


.border-container {
  overflow: hidden;
  border: ridge 3Q transparent;
  border-radius: 30px;
  background-image: 
    linear-gradient(white, white), 
    linear-gradient(to bottom right, rgb(var(--accent-color-rgb)), rgb(var(--background-color-rgb)), rgb(var(--accent-color-rgb)));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.auth-form {
  color: rgba(var(--background-color), 0.5);
  min-width: 350px;
  box-shadow: 0 4px 8px 0 rgba(var(--default-color-rgb), 0.2), 0 6px 20px 0 rgba(var(--default-color-rgb), 0.19);
  background-color: rgba(var(--accent-color-hover-rgb), 0.7);
  /* border-radius: 25px; */
}


.auth-form > div {
  padding: 15px;
}

.auth-form .form-header {
  pointer-events: none;
  user-select: none;
  display: block;
  text-align: center;
}

.auth-form .form-body {
  display: block;
  background-color: #ffffff;
}

.form-input {
  margin: 30px 15px;
}

.form-input > label {
  pointer-events: none;
  user-select: none;
  padding-bottom: 5px;
}

.form-input > label, input {
  display: block;
}

.auth-form .form-footer {
  text-align: center;
}

.auth-form .form-footer a {
  color: rgb(var(--background-color-rgb));
}

.auth-form .form-footer a:hover {
  /* color: rgb(var(--accent-color-rgb)); */
  color: #D09F0F;
}

.form-submit {
  display: block;
  width: 100%;
  padding: 5px;
  border: 0;
  border-radius: 3px;
  background-color: var(--accent-color);
  color: var(--background-color)
}

.form-submit:hover {
  /* background-color: var(--accent-color-hover); */
  background-color: #D09F0F;
  color: var(--default-color);
}