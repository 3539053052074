
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  --heading-color: #32353a;
  --heading-color-rgb: 50, 53, 58;
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  background-repeat: no-repeat;
  /* background-image: url("/hero_1.jpg"); */
  background-color: rgba(var(--background-color-rgb), 0.9);
  background-position: center bottom -20rem;

  transition: 0.3s;
  opacity: 0.95;
  background-size: 100%;
  background-size: cover;
  background-blend-mode: normal;
  
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
}

.footer::before {
  content: '';
  --background-color: #f4f4f4;
  color: var(--default-color);
  font-size: 14px;
  padding-bottom: 50px;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 0;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(var(--default-color-rgb), 0.5);
  font-size: 16px;
  color: rgba(var(--default-color-rgb), 0.5);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.footer h4 {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 5px rgb(var(--background-color-rgb));
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(var(--default-color-rgb), 0.8);
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact {
  margin-bottom: 30px;
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: rgba(var(--default-color-rgb), 0.05);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}
