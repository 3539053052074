
/* Global Colors */
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --accent-color: #333366;
  --accent-color-rgb: 51, 51, 102;
  --accent-color-hover: #8dcfe7;
  --accent-color-hover-rgb: 141, 207, 231;
  --heading-color: #32353a;
  --heading-color-rgb: 50, 53, 58;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}
/*-----------------------------------
# CARNET
------------------------------------*/
/* 
*/

.carnet {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-color: var(--accent-color-hover);
  background-color: rgba(var(--accent-color-hover-rgb), 0.1);
  min-width: 600px;
  min-height: '50rem';
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.carnet .bg-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  z-index: -1;
  position: absolute;
  width: 100%;
  max-width: 400px;
  height: auto;
  opacity: 0.2;
  top: 100px;
  left: 30px;
}

.carnet .title {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.carnet .title p {
  margin: 0 10px;
  font-size: 25px
}

.carnet .title .club-icon {
  width: 35px;
}

.carnet-content {
  margin: 4px;
  padding: 0px 5px;
  padding-bottom: 15px;
  display: flex;
}

.carnet-content .img-section {
  position: relative;
  min-width: 200px;
}

.carnet-content .img-section img {
  border-radius: 7px 7px 0 0;
  width: 200px;
}

.carnet-content .img-section .rut-section {
  background-color: rgba(var(--background-color-rgb), 0.9);
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  width: auto;
  text-align: center;
}


.profile-section {
  width: 100%;
  margin-left: 10px;
  padding: 0px 5px;
}

.profile-section .profile-content {
  background-color: rgba(var(--background-color-rgb), 0.5);
  margin-left: 10px;
  padding: 10px;
}

@media only screen and (max-width: 755px) {
  .carnet {
    transform: rotate(-90deg); 
  }
}
